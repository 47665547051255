/*
 * Confirmation Messages
 *
 * This contains all the text for the Confirmation container.
 */

import { defineMessages } from 'react-intl';

export const confirmationScope = 'app.containers.confirmationScope';

export const confirmationMessages = {
  reservationTitle: {
    id: `${confirmationScope}.reservationTitle`,
    defaultMessage: 'Vous y êtes, c’est bientôt le grand départ !',
    en: "You're almost there, the big departure is coming soon!",
    it: 'Ci siamo quasi, è quasi tempo per la grande partenza!',
    es: '¡Ya casi están, pronto será el gran partida!',
    de: 'Ihr seid fast da, bald geht die große Abreise los!',
    nl: 'Jullie zijn er bijna, het grote vertrek komt eraan!',
  },
  emailConfirmation: {
    id: `${confirmationScope}.emailConfirmation`,
    defaultMessage: 'Vos billets ont également été envoyés à l’adresse :',
    en: 'Your tickets have also been sent to the address:',
    it: "I tuoi biglietti sono stati inviati all'indirizzo:",
    es: 'Tus boletos también han sido enviados a la dirección:',
    de: 'Ihre Tickets wurden auch an die Adresse gesendet:',
    nl: 'Uw tickets zijn ook naar het adres gestuurd:',
  },
  emailUsedForOrder: {
    id: `${confirmationScope}.emailUsedForOrder`,
    defaultMessage: "L'adresse email associée à votre commande est :",
    en: 'The email address associated with your order is:',
    it: "L'indirizzo email associato al tuo ordine è:",
    es: 'La dirección de correo electrónico asociada con tu pedido es:',
    de: 'Die mit Ihrer Bestellung verknüpfte E-Mail-Adresse ist:',
    nl: 'Het e-mailadres dat aan uw bestelling is gekoppeld, is:',
  },
  yourOrderId: {
    id: `${confirmationScope}.yourOrderId`,
    defaultMessage: 'Votre numéro de réservation est :',
    en: 'Your reservation number is:',
    it: 'Il tuo numero di prenotazione è:',
    es: 'Su número de reserva es:',
    de: 'Ihre Buchungsnummer lautet:',
    nl: 'Uw reserveringsnummer is:',
  },
  ticketsAvailable: {
    id: `${confirmationScope}.ticketsAvailable`,
    defaultMessage: 'Vos billets sont aussi dispo’ dans l’application.',
    en: 'Your tickets are also available in the app.',
    it: "I tuoi biglietti sono disponibili anche nell'app.",
    es: 'Tus boletos también están disponibles en la aplicación.',
    de: 'Ihre Tickets sind auch in der App verfügbar.',
    nl: 'Uw tickets zijn ook beschikbaar in de app.',
  },
  accessTickets: {
    id: `${confirmationScope}.accessTickets`,
    defaultMessage: 'Accéder à vos billets sans les imprimer.',
    en: 'Access your tickets without printing them.',
    it: 'Accedi ai tuoi biglietti senza stamparli.',
    es: 'Accede a tus boletos sin imprimirlos.',
    de: 'Greifen Sie auf Ihre Tickets zu, ohne sie auszudrucken.',
    nl: 'Toegang tot uw tickets zonder ze af te drukken.',
  },
  manageReservations: {
    id: `${confirmationScope}.manageReservations`,
    defaultMessage: 'Gérer vos réservations au dernier moment.',
    en: 'Manage your last-minute reservations.',
    it: "Gestisci le tue prenotazioni dell'ultimo minuto.",
    es: 'Administra tus reservas de último momento.',
    de: 'Verwalten Sie Ihre Last-Minute-Reservierungen.',
    nl: 'Beheer uw last-minute reserveringen.',
  },
  ticketsWithinReach: {
    id: `${confirmationScope}.ticketsWithinReach`,
    defaultMessage: 'Garder vos billets à portée de main.',
    en: 'Keep your tickets within reach.',
    it: 'Tieni i tuoi biglietti a portata di mano.',
    es: 'Mantén tus boletos al alcance.',
    de: 'Behalten Sie Ihre Tickets griffbereit.',
    nl: 'Houd uw tickets binnen handbereik.',
  },
  accessReservation: {
    id: `${confirmationScope}.accessReservation`,
    defaultMessage: 'Accéder à votre réservation depuis l’application.',
    en: 'Access your reservation from the application.',
    it: "Accedi alla tua prenotazione dall'applicazione.",
    es: 'Acceda a su reserva desde la aplicación.',
    de: 'Greifen Sie auf Ihre Reservierung über die Anwendung zu.',
    nl: 'Toegang krijgen tot uw reservering vanuit de applicatie.',
  },
  itsBelow: {
    id: `${confirmationScope}.itsBelow`,
    defaultMessage: 'Ça se passe en-dessous.',
    en: "It's down below.",
    it: 'È qui sotto.',
    es: 'Está aquí abajo.',
    de: 'Es ist unten.',
    nl: 'Het staat hieronder.',
  },

  accessReservationButton: {
    id: `${confirmationScope}.accessReservationButton`,
    defaultMessage: 'Accéder à ma réservation',
    en: 'Access My Reservation',
    it: 'Accedi Alla Mia Prenotazione',
    es: 'Acceder a Mi Reserva',
    de: 'Zugriff auf Meine Reservierung',
    nl: 'Toegang Tot Mijn Reservering',
  },
  faqsTitle: {
    id: `${confirmationScope}.faqsTitle`,
    defaultMessage: 'Questions fréquentes',
    en: 'Frequently Asked Questions',
    it: 'Domande frequenti',
    es: 'Preguntas frecuentes',
    de: 'Häufig gestellte Fragen',
    nl: 'Veelgestelde vragen',
  },
  contactUsTitle: {
    id: `${confirmationScope}.contactUsTitle`,
    defaultMessage: 'Un coup de pouce ?',
    en: 'Need a hand?',
    it: 'Serve una mano?',
    es: '¿Necesitas ayuda?',
    de: 'Brauchst du Hilfe?',
    nl: 'Een helpende hand?',
  },
  contactUsMessage: {
    id: `${confirmationScope}.contactUsMessage`,
    defaultMessage:
      'Écrivez-nous ! On vous répond le plus vite possible (+1h à l’heure du goûter).',
    en: "Write us! We'll respond as quickly as possible (+1h at snack time).",
    it: "Scrivici! Risponderemo il prima possibile (+1h all'ora della merenda).",
    es: '¡Escríbenos! Responderemos lo más rápido posible (+1h a la hora de la merienda).',
    de: 'Schreiben Sie uns! Wir antworten so schnell wie möglich (+1h zur Snackzeit).',
    nl: 'Schrijf ons! We reageren zo snel mogelijk (+1u bij de theetijd).',
  },
  contactUsButton: {
    id: `${confirmationScope}.contactUsButton`,
    defaultMessage: 'Contacter le (super) service client',
    en: 'Contact the (super) customer service',
    it: 'Contatta il servizio clienti (super)',
    es: 'Contactar al (súper) servicio al cliente',
    de: 'Kontaktieren Sie den (super) Kundenservice',
    nl: 'Neem contact op met de (super) klantenservice',
  },
  retrieveTickets: {
    id: `${confirmationScope}.retrieveTickets`,
    defaultMessage: 'Comment retrouver mes billets ?',
    en: 'How do I find my tickets?',
    it: 'Come posso trovare i miei biglietti?',
    es: '¿Cómo encuentro mis boletos?',
    de: 'Wie finde ich meine Tickets?',
    nl: 'Hoe vind ik mijn tickets?',
  },
  howToCancelTicket: {
    id: `${confirmationScope}.howToCancelTicket`,
    defaultMessage: 'Comment annuler un billet ?',
    en: 'How to cancel a ticket?',
    it: 'Come annullare un biglietto?',
    es: '¿Cómo cancelar un boleto?',
    de: 'Wie kann ich ein Ticket stornieren?',
    nl: 'Hoe een ticket annuleren?',
  },
  exchangeTicket: {
    id: `${confirmationScope}.exchangeTicket`,
    defaultMessage: 'Comment échanger un billet ?',
    en: 'How to exchange a ticket?',
    it: 'Come scambiare un biglietto?',
    es: '¿Cómo cambiar un boleto?',
    de: 'Wie tauscht man ein Ticket um?',
    nl: 'Hoe een ticket omruilen?',
  },
  changeEmail: {
    id: `${confirmationScope}.changeEmail`,
    defaultMessage: 'Comment changer mon mail de réservation ?',
    en: 'How to change my reservation email?',
    it: 'Come cambiare la mia email di prenotazione?',
    es: '¿Cómo cambiar mi correo electrónico de reserva?',
    de: 'Wie ändere ich meine Reservierungs-E-Mail?',
    nl: 'Hoe verander ik mijn reserveringsmail?',
  },
  failedOrderWarningContent: {
    id: `${confirmationScope}.failedOrderWarningContent`,
    defaultMessage: 'Pas de panique ! Nous avons la solution. 🔧',
    en: 'Don’t panic! We have the solution. 🔧',
    it: 'Niente paura! Abbiamo la soluzione. 🔧',
    es: '¡No te preocupes! Tenemos la solución. 🔧',
    de: 'Keine Panik! Wir haben die Lösung. 🔧',
    nl: 'Geen paniek! We hebben de oplossing. 🔧',
  },
  fullFailedOrderWarningTitle: {
    id: `${confirmationScope}.fullFailedOrderWarningTitle`,
    defaultMessage: 'Nous n’avons pas réussi à réserver votre réservation.',
    en: 'We were unable to make your reservation.',
    it: 'Non siamo riusciti a prenotare la tua prenotazione.',
    es: 'No pudimos hacer tu reserva.',
    de: 'Wir konnten Ihre Reservierung nicht durchführen.',
    nl: 'We konden uw reservering niet maken.',
  },
  makeNewReservation: {
    id: `${confirmationScope}.makeNewReservation`,
    defaultMessage: 'Recommencer ma réservation',
    en: 'Make a new reservation',
    it: 'Effettuare una nuova prenotazione',
    es: 'Hacer una nueva reserva',
    de: 'Eine neue Reservierung vornehmen',
    nl: 'Maak een nieuwe reservering',
  },
  yourTrip: {
    id: `${confirmationScope}.yourTrip`,
    defaultMessage: 'Votre voyage',
    en: 'Your Trip',
    it: 'Il Tuo Viaggio',
    es: 'Tu Viaje',
    de: 'Deine Reise',
    nl: 'Uw Reis',
  },
  addToCalendar: {
    id: `${confirmationScope}.addToCalendar`,
    defaultMessage: 'Ajouter ce voyage dans mon calendrier',
    en: 'Add this trip to my calendar',
    it: 'Aggiungi questo viaggio al mio calendario',
    es: 'Agregar este viaje a mi calendario',
    de: 'Diesen Trip zu meinem Kalender hinzufügen',
    nl: 'Voeg deze reis toe aan mijn agenda',
  },
  explanationWhatHappenedTitle: {
    id: `${confirmationScope}.explanationWhatHappenedTitle`,
    defaultMessage: 'D’abord, que s’est-il passé ?',
    en: 'First, what happened?',
    it: 'Prima di tutto, cosa è successo?',
    es: 'Primero, ¿qué pasó?',
    de: 'Zuerst, was ist passiert?',
    nl: 'Eerst, wat is er gebeurd?',
  },
  explanationWhatHappenedContent: {
    id: `${confirmationScope}.explanationWhatHappenedContent`,
    defaultMessage:
      'L’erreur se trouve de notre côté. Nous n’avons pas réussi à réserver votre trajet auprès de nos compagnies de transport partenaires.',
    en: 'The error is on our side. We were unable to book your journey with our partner transport companies.',
    it: "L'errore è dal nostro lato. Non siamo riusciti a prenotare il tuo viaggio presso le nostre compagnie di trasporto partner.",
    es: 'El error está de nuestro lado. No pudimos reservar tu viaje con nuestras compañías de transporte asociadas.',
    de: 'Der Fehler liegt bei uns. Wir konnten Ihre Reise nicht bei unseren Partner-Transportunternehmen buchen.',
    nl: 'Het probleem ligt bij ons. We konden uw reis niet boeken bij onze partnervervoersbedrijven.',
  },
  duplicateOrderWarning: {
    id: `${confirmationScope}.duplicateOrderWarning`,
    defaultMessage: 'Nous avons détecté une double commande.',
    en: 'We have detected a duplicate order.',
    it: 'Abbiamo rilevato un ordine duplicato.',
    es: 'Hemos detectado un pedido duplicado.',
    de: 'Wir haben eine doppelte Bestellung erkannt.',
    nl: 'We hebben een dubbele bestelling gedetecteerd.',
  },
  explanationWhyTitle: {
    id: `${confirmationScope}.explanationWhyTitle`,
    defaultMessage: 'Pourquoi ?',
    en: 'Why?',
    it: 'Perché?',
    es: '¿Por qué?',
    de: 'Warum?',
    nl: 'Waarom?',
  },
  explanationDoubleOrderTitle: {
    id: `${confirmationScope}.explanationDoubleOrderTitle`,
    defaultMessage: 'À savoir',
    en: 'What you need to know',
    it: 'Cosa sapere',
    es: 'Lo que necesitas saber',
    de: 'Was Sie wissen müssen',
    nl: 'Wat u moet weten',
  },
  explanationWhyContentDescription: {
    id: `${confirmationScope}.explanationWhyContentDescription`,
    defaultMessage:
      'Il arrive que certaines de nos réservations échouent. Il y’a plusieurs raisons possibles :',
    en: 'Sometimes, some of our reservations fail. There could be several possible reasons:',
    it: 'A volte, alcune delle nostre prenotazioni falliscono. Potrebbero esserci diverse ragioni possibili:',
    es: 'En ocasiones, algunas de nuestras reservas fallan. Puede haber varias razones posibles:',
    de: 'Manchmal scheitern einige unserer Buchungen. Es könnte mehrere mögliche Gründe geben:',
    nl: 'Het komt soms voor dat sommige van onze reserveringen mislukken. Er kunnen verschillende mogelijke redenen zijn:',
  },
  explanationWhyContentErrors: {
    id: `${confirmationScope}.explanationWhyContentErrors`,
    defaultMessage:
      '<li>Le trajet n’est plus disponible</li><li>Le trajet est complet</li><li>Le prix peut varier au moment de notre réservation</li><li>...</li>',
    en: '<li>The journey is no longer available</li><li>The journey is full</li><li>The price may vary at the time of our reservation</li><li>...</li>',
    it: '<li>Il viaggio non è più disponibile</li><li>Il viaggio è completo</li><li>Il prezzo potrebbe variare al momento della prenotazione</li><li>...</li>',
    es: '<li>El viaje ya no está disponible</li><li>El viaje está completo</li><li>El precio puede variar en el momento de nuestra reserva</li><li>...</li>',
    de: '<li>Die Reise ist nicht mehr verfügbar</li><li>Die Reise ist ausgebucht</li><li>Der Preis kann sich zum Zeitpunkt unserer Reservierung ändern</li><li>...</li>',
    nl: '<li>De reis is niet langer beschikbaar</li><li>De reis is volgeboekt</li><li>De prijs kan variëren op het moment van onze reservering</li><li>...</li>',
  },
  explanationWhyContentConclusion: {
    id: `${confirmationScope}.explanationWhyContentConclusion`,
    defaultMessage: 'C’est assez rare mais ça arrive encore (un peu).',
    en: "It's quite rare but it still happens (a bit).",
    it: "È abbastanza raro ma succede ancora (un po').",
    es: 'Es bastante raro pero aún sucede (un poco).',
    de: 'Es ist ziemlich selten, aber es passiert immer noch (ein bisschen).',
    nl: 'Het is behoorlijk zeldzaam maar het gebeurt nog steeds (een beetje).',
  },

  explanationDuplicateOrderContentDescription: {
    id: `${confirmationScope}.explanationDuplicateOrderContentDescription`,
    defaultMessage:
      "Si vous avez saisi vos informations de paiement deux fois, pas de panique ! Notre équipe de support est là pour vous accompagner et s'assurer que :",
    en: "If you have entered your payment information twice, don't worry! Our support team is here to assist you and ensure that:",
    it: 'Se hai inserito le tue informazioni di pagamento due volte, non preoccuparti! Il nostro team di supporto è qui per aiutarti e assicurarsi che:',
    es: 'Si has introducido tus datos de pago dos veces, ¡no te preocupes! Nuestro equipo de soporte está aquí para ayudarte y asegurarse de que:',
    de: 'Wenn Sie Ihre Zahlungsinformationen zweimal eingegeben haben, keine Sorge! Unser Support-Team ist hier, um Ihnen zu helfen und sicherzustellen, dass:',
    nl: 'Als u uw betalingsgegevens twee keer hebt ingevoerd, maak u geen zorgen! Ons ondersteuningsteam is hier om u te helpen en ervoor te zorgen dat:',
  },
  explanationDuplicateOrderContentErrors: {
    id: `${confirmationScope}.explanationDuplicateOrderContentErrors`,
    defaultMessage:
      "<li>Vous ne soyez débité qu'une seule fois.</li> <li>Vous disposiez de tout le nécessaire pour finaliser votre voyage.</li>",
    en: '<li>You are only charged once.</li> <li>You have everything you need to finalize your trip.</li>',
    it: '<li>Verrà addebitato solo una volta.</li> <li>Avrai tutto il necessario per completare il tuo viaggio.</li>',
    es: '<li>Solo se te cobrará una vez.</li> <li>Dispondrás de todo lo necesario para finalizar tu viaje.</li>',
    de: '<li>Es wird nur einmal abgebucht.</li> <li>Sie haben alles, was Sie benötigen, um Ihre Reise abzuschließen.</li>',
    nl: '<li>U wordt slechts één keer in rekening gebracht.</li> <li>U heeft alles wat u nodig heeft om uw reis te voltooien.</li>',
  },
  explanationDuplicateOrderContentConclusion: {
    id: `${confirmationScope}.explanationDuplicateOrderContentConclusion`,
    defaultMessage:
      "Si vous souhaitez malgré tout effectuer une nouvelle réservation sur le même trajet, nous vous conseillons d'essayer à nouveau dans une heure.",
    en: 'If you still wish to make a new booking on the same route, we recommend trying again in an hour.',
    it: "Se desideri comunque effettuare una nuova prenotazione sulla stessa tratta, ti consigliamo di riprovare tra un'ora.",
    es: 'Si aún deseas realizar una nueva reserva en la misma ruta, te recomendamos intentar de nuevo en una hora.',
    de: 'Wenn Sie dennoch eine neue Buchung auf derselben Strecke vornehmen möchten, empfehlen wir, es in einer Stunde noch einmal zu versuchen.',
    nl: 'Als u toch een nieuwe reservering voor dezelfde route wilt maken, raden we aan om het over een uur opnieuw te proberen.',
  },

  explanatioAndNowTitle: {
    id: `${confirmationScope}.explanatioAndNowTitle`,
    defaultMessage: 'Et maintenant ?',
    en: 'And now?',
    it: 'E ora?',
    es: '¿Y ahora?',
    de: 'Und jetzt?',
    nl: 'En nu?',
  },
  explanationDoubleOrderAndNowTitle: {
    id: `${confirmationScope}.explanationDoubleOrderAndNowTitle`,
    defaultMessage: 'Que faire ?',
    en: 'What to do?',
    it: 'Cosa fare?',
    es: '¿Qué hacer?',
    de: 'Was tun?',
    nl: 'Wat te doen?',
  },
  explanatioAndNowContentSimple: {
    id: `${confirmationScope}.explanatioAndNowContentSimple`,
    defaultMessage: 'Très simple !',
    en: 'Very simple!',
    it: 'Molto semplice!',
    es: '¡Muy simple!',
    de: 'Sehr einfach!',
    nl: 'Heel eenvoudig!',
  },
  explanatioAndNowContentVerySimple: {
    id: `${confirmationScope}.explanatioAndNowContentVerySimple`,
    defaultMessage: 'Hyper simple !',
    en: 'Hyper simple!',
    it: 'Ipersemplice!',
    es: '¡Hiper simple!',
    de: 'Hyper einfach!',
    nl: 'Hyper eenvoudig!',
  },
  explanatioAndNowContentRefundList: {
    id: `${confirmationScope}.explanatioAndNowContentRefundList`,
    defaultMessage: `
    <li>
    <strong>
      Nous avons déjà procédé au remboursement de votre commande.
    </strong>
    Il sera sur votre compte dans 3 à 5 jours.
  </li>
  <li>
    Un email de validation du remboursement vient d’être envoyé sur
    l’adresse de réservation.
  </li>
  <li>
    Vous pouvez réserver de nouveau votre trajet en cliquant sur le
    bouton en dessous.
  </li>
  <li>Vous allez pouvoir partir !</li>
    `,
    en: `
      <li>
        <strong>
          We have already processed the refund for your order.
        </strong>
        It will be in your account within 3 to 5 days.
      </li>
      <li>
        A refund validation email has just been sent to the booking address.
      </li>
      <li>
        You can book your journey again by clicking the button below.
      </li>
      <li>You're good to go!</li>
    `,
    it: `
      <li>
        <strong>
          Abbiamo già elaborato il rimborso per il tuo ordine.
        </strong>
        Sarà accreditato sul tuo account entro 3-5 giorni.
      </li>
      <li>
        È appena stato inviato un'email di convalida del rimborso all'indirizzo di prenotazione.
      </li>
      <li>
        Puoi prenotare nuovamente il tuo viaggio cliccando sul pulsante qui sotto.
      </li>
      <li>Puoi partire!</li>
    `,
    es: `
      <li>
        <strong>
          Ya hemos procesado el reembolso de tu pedido.
        </strong>
        Estará en tu cuenta dentro de 3 a 5 días.
      </li>
      <li>
        Acabamos de enviar un correo electrónico de validación del reembolso a la dirección de reserva.
      </li>
      <li>
        Puedes reservar tu viaje nuevamente haciendo clic en el botón de abajo.
      </li>
      <li>¡Ya puedes irte!</li>
    `,
    de: `
      <li>
        <strong>
          Wir haben die Rückerstattung für Ihre Bestellung bereits bearbeitet.
        </strong>
        Es wird innerhalb von 3 bis 5 Tagen auf Ihrem Konto sein.
      </li>
      <li>
        Eine E-Mail zur Bestätigung der Rückerstattung wurde gerade an die Buchungsadresse gesendet.
      </li>
      <li>
        Sie können Ihre Reise erneut buchen, indem Sie auf den unten stehenden Button klicken.
      </li>
      <li>Sie können loslegen!</li>
    `,
    nl: `
      <li>
        <strong>
          We hebben de terugbetaling voor je bestelling al verwerkt.
        </strong>
        Het zal binnen 3 tot 5 dagen op je rekening staan.
      </li>
      <li>
        Er is zojuist een e-mail met validatie van de terugbetaling verzonden naar het boekingsadres.
      </li>
      <li>
        Je kunt je reis opnieuw boeken door op de onderstaande knop te klikken.
      </li>
      <li>Je kunt gaan!</li>
    `,
  },
  explanatioAndNowContentNotRefundList: {
    id: `${confirmationScope}.explanatioAndNowContentNotRefundList`,
    defaultMessage: `
    <li>
      Nous avons déjà connaissance du problème et nous sommes sur le coup. Nous allons revenir vers vous le plus vite possible.
    </li>
    <li>
      On va tout faire pour résoudre le problème avant votre départ.
    </li>
    <li>
      De votre côté, surtout n'achetez pas un autre billet sans message de notre service client.
    </li>
    <li>
      Si vous avez besoin de quelque chose, une crainte ou besoin d'échanger, contactez-nous.
    </li>
  `,
    en: `
    <li>
      We are already aware of the issue and are working on it. We will get back to you as soon as possible.
    </li>
    <li>
      We will do our best to resolve the issue before your departure.
    </li>
    <li>
      On your part, please do not purchase another ticket without a message from our customer service.
    </li>
    <li>
      If you need anything, have concerns, or need to make an exchange, please contact us.
    </li>
  `,
    it: `
    <li>
      Siamo già a conoscenza del problema e stiamo lavorando su di esso. Ti risponderemo il prima possibile.
    </li>
    <li>
      Faremo del nostro meglio per risolvere il problema prima della tua partenza.
    </li>
    <li>
      Dal tuo lato, ti preghiamo di non acquistare un altro biglietto senza un messaggio dal nostro servizio clienti.
    </li>
    <li>
      Se hai bisogno di qualcosa, hai preoccupazioni o hai bisogno di effettuare uno scambio, contattaci.
    </li>
  `,
    es: `
    <li>
      Ya estamos al tanto del problema y estamos trabajando en ello. Nos pondremos en contacto contigo lo antes posible.
    </li>
    <li>
      Haremos todo lo posible por resolver el problema antes de tu partida.
    </li>
    <li>
      Por tu parte, por favor, no compres otro billete sin un mensaje de nuestro servicio de atención al cliente.
    </li>
    <li>
      Si necesitas algo, tienes preocupaciones o necesitas hacer un cambio, por favor, contáctanos.
    </li>
  `,
    de: `
    <li>
      Wir sind uns bereits des Problems bewusst und arbeiten daran. Wir werden uns so schnell wie möglich bei Ihnen melden.
    </li>
    <li>
      Wir werden unser Bestes tun, um das Problem vor Ihrer Abreise zu lösen.
    </li>
    <li>
      Bitte kaufen Sie auf Ihrer Seite keinen weiteren Fahrschein, ohne eine Nachricht von unserem Kundenservice.
    </li>
    <li>
      Wenn Sie etwas benötigen, Bedenken haben oder einen Umtausch vornehmen müssen, kontaktieren Sie uns bitte.
    </li>
  `,
    nl: `
    <li>
      We zijn al op de hoogte van het probleem en werken eraan. We nemen zo snel mogelijk contact met u op.
    </li>
    <li>
      We zullen ons best doen om het probleem op te lossen voordat u vertrekt.
    </li>
    <li>
      Koop alstublieft geen ander ticket zonder bericht van onze klantenservice.
    </li>
    <li>
      Als u iets nodig heeft, zorgen heeft of een ruiling wilt maken, neem dan contact met ons op.
    </li>
  `,
  },
  explanatioAndNowContentDuplicateOrderList: {
    id: `${confirmationScope}.explanatioAndNowContentDuplicateOrderList`,
    defaultMessage:
      '<li>Contactez notre service client</li> <li>Patientez une heure avant de réessayer si vous souhaitez malgré tout effectuer une nouvelle réservation sur le même trajet</li>',
    en: '<li>Contact our customer service</li> <li>Wait an hour before trying again if you still want to make a new booking on the same route</li>',
    it: "<li>Contatta il nostro servizio clienti</li> <li>Attendi un'ora prima di riprovare se desideri comunque effettuare una nuova prenotazione sulla stessa tratta</li>",
    es: '<li>Contacta con nuestro servicio al cliente</li> <li>Espera una hora antes de intentarlo de nuevo si aún deseas realizar una nueva reserva en la misma ruta</li>',
    de: '<li>Kontaktieren Sie unseren Kundenservice</li> <li>Warten Sie eine Stunde, bevor Sie es erneut versuchen, wenn Sie dennoch eine neue Buchung auf derselben Strecke vornehmen möchten</li>',
    nl: '<li>Neem contact op met onze klantenservice</li> <li>Wacht een uur voordat u het opnieuw probeert als u toch een nieuwe reservering voor dezelfde route wilt maken</li>',
  },

  bookingErrorApologyTitle: {
    id: `${confirmationScope}.bookingErrorApologyTitle`,
    defaultMessage: 'Avant de se quitter...',
    en: 'Before we part ways...',
    it: 'Prima di separarci...',
    es: 'Antes de despedirnos...',
    de: 'Bevor wir uns verabschieden...',
    nl: 'Voordat we uit elkaar gaan...',
  },
  bookingErrorApologyContent: {
    id: `${confirmationScope}.bookingErrorApologyContent`,
    defaultMessage:
      'L’équipe vous présente ses excuses pour le désagrément. C’est un cas assez rare mais qui persiste malheureusement sur notre service et d’autres services concurrents. Merci pour votre compréhension et votre patience. Au plaisir de vous revoir très (très) vite ! 👋',
    en: 'The team apologizes for the inconvenience. This is a rather rare but unfortunately persistent case on our service and other competing services. Thank you for your understanding and patience. We look forward to seeing you again very (very) soon! 👋',
    it: "Il team si scusa per l'inconveniente. Si tratta di un caso piuttosto raro ma purtroppo persistente sul nostro servizio e su altri servizi concorrenti. Grazie per la vostra comprensione e pazienza. Non vediamo l'ora di rivedervi molto (molto) presto! 👋",
    es: 'El equipo se disculpa por las molestias. Este es un caso bastante raro pero desafortunadamente persistente en nuestro servicio y en otros servicios competidores. Gracias por su comprensión y paciencia. ¡Esperamos verte nuevamente muy (muy) pronto! 👋',
    de: 'Das Team entschuldigt sich für die Unannehmlichkeiten. Dies ist ein eher seltener, aber leider anhaltender Fall auf unserem Service und auf anderen konkurrierenden Diensten. Vielen Dank für Ihr Verständnis und Ihre Geduld. Wir freuen uns darauf, Sie bald wiederzusehen! 👋',
    nl: 'Het team verontschuldigt zich voor het ongemak. Dit is een vrij zeldzame, maar helaas aanhoudende situatie op onze service en andere concurrerende diensten. Bedankt voor uw begrip en geduld. We kijken ernaar uit om u zeer (zeer) binnenkort weer te zien! 👋',
  },
  bookingErrorToast: {
    id: `${confirmationScope}.bookingErrorToast`,
    defaultMessage: 'Une erreur s’est glissée dans votre réservation.',
    en: 'An error has occurred in your booking.',
    it: 'Si è verificato un errore nella prenotazione.',
    es: 'Se ha producido un error en su reserva.',
    de: 'Ein Fehler ist bei Ihrer Buchung aufgetreten.',
    nl: 'Er is een fout opgetreden in uw reservering.',
  },
  bookingErrorWarningContent: {
    id: `${confirmationScope}.bookingErrorWarningContent`,
    defaultMessage: 'Votre voyage n’a pas pu être réservé en intégralité.',
    en: 'Your journey could not be booked in full.',
    it: 'Il tuo viaggio non è stato prenotato completamente.',
    es: 'Tu viaje no pudo ser reservado en su totalidad.',
    de: 'Ihre Reise konnte nicht vollständig gebucht werden.',
    nl: 'Uw reis kon niet volledig worden geboekt.',
  },
  solveBookingError: {
    id: `${confirmationScope}.solveBookingError`,
    defaultMessage: 'Résoudre le problème',
    en: 'Solve the problem',
    it: 'Risolvere il problema',
    es: 'Resolver el problema',
    de: 'Das Problem lösen',
    nl: 'Het probleem oplossen',
  },
  contactCustomerService: {
    id: `${confirmationScope}.contactCustomerService`,
    defaultMessage: 'Contacter le service client',
    en: 'Contact Customer Service',
    it: 'Contattare il servizio clienti',
    es: 'Contactar al servicio al cliente',
    de: 'Kundenservice kontaktieren',
    nl: 'Neem contact op met de klantenservice',
  },
  bookingErrorOtherTickets: {
    id: `${confirmationScope}.bookingErrorOtherTickets`,
    defaultMessage:
      'Vos autres billets sont déjà disponibles dans l’application.',
    en: 'Your other tickets are already available in the application.',
    it: "I tuoi altri biglietti sono già disponibili nell'applicazione.",
    es: 'Tus otros boletos ya están disponibles en la aplicación.',
    de: 'Ihre anderen Tickets sind bereits in der Anwendung verfügbar.',
    nl: 'Uw andere tickets zijn al beschikbaar in de applicatie.',
  },
  manageReservation: {
    id: `${confirmationScope}.manageReservation`,
    defaultMessage: 'Gérer ma réservation',
    en: 'Manage My Reservation',
    it: 'Gestisci la mia prenotazione',
    es: 'Gestionar mi reserva',
    de: 'Meine Buchung verwalten',
    nl: 'Mijn reservering beheren',
  },
  ouigoWarningTitle: {
    id: `${confirmationScope}.ouigoWarningTitle`,
    defaultMessage: 'Ils arrivent... doucement mais sûrement !',
    en: 'They are coming... slowly but surely!',
    it: 'Stanno arrivando... lentamente ma sicuramente!',
    es: 'Están llegando... poco a poco pero con seguridad.',
    de: 'Sie kommen... langsam aber sicher!',
    nl: 'Ze komen eraan... langzaam maar zeker!',
  },
  ouigoWarningMessage1: {
    id: `${confirmationScope}.ouigoWarningMessage1`,
    defaultMessage:
      'OUIGO c’est un peu notre partenaire particulier. Celui qui fait ce qu’il veut, quand il veut.',
    en: 'OUIGO is a bit like our special partner. The one who does what it wants, whenever it wants.',
    it: "OUIGO è un po' il nostro partner speciale. Quello che fa ciò che vuole, quando vuole.",
    es: 'OUIGO es un poco como nuestro socio especial. El que hace lo que quiere, cuando quiere.',
    de: 'OUIGO ist so etwas wie unser besonderer Partner. Derjenige, der tut, was er will, wann er will.',
    nl: 'OUIGO is een beetje onze speciale partner. Degene die doet wat hij wil, wanneer hij wil.',
  },
  ouigoWarningMessage2: {
    id: `${confirmationScope}.ouigoWarningMessage2`,
    defaultMessage:
      'Votre voyage comprend un trajet avec la compagnie, <strong>vos billets seront disponibles seulement 4 jours avant votre départ.</strong> Nous ne pouvons malheureusement rien y faire, il va falloir faire preuve de patience.',
    en: 'Your journey includes a leg with the company, <strong>your tickets will be available only 4 days before your departure.</strong> Unfortunately, there is nothing we can do about it; you will need to be patient.',
    it: 'Il tuo viaggio include un tratto con la compagnia, <strong>i tuoi biglietti saranno disponibili solo 4 giorni prima della partenza.</strong> Purtroppo, non possiamo farci niente; dovrai avere pazienza.',
    es: 'Tu viaje incluye un trayecto con la compañía, <strong>tus boletos estarán disponibles solo 4 días antes de tu partida.</strong> Lamentablemente, no podemos hacer nada al respecto; tendrás que ser paciente.',
    de: 'Ihre Reise beinhaltet eine Strecke mit der Gesellschaft, <strong>Ihre Tickets werden erst 4 Tage vor Ihrer Abreise verfügbar sein.</strong> Leider können wir nichts dagegen tun; Sie müssen geduldig sein.',
    nl: 'Uw reis omvat een traject met het bedrijf, <strong>uw tickets zullen pas 4 dagen voor vertrek beschikbaar zijn.</strong> Helaas kunnen we er niets aan doen; u zult geduld moeten hebben.',
  },
  ouigoWarningMessage3: {
    id: `${confirmationScope}.ouigoWarningMessage3`,
    defaultMessage:
      "ps : si pour une raison inconnue vous n'avez pas vos billets 4 jours avant votre départ, écrivez-nous. 💌",
    en: 'ps: If for an unknown reason you do not have your tickets 4 days before your departure, please contact us. 💌',
    it: 'ps: Se per un motivo sconosciuto non avete i vostri biglietti 4 giorni prima della partenza, vi preghiamo di contattarci. 💌',
    es: 'ps: Si por alguna razón desconocida no tienes tus boletos 4 días antes de tu partida, contáctanos por favor. 💌',
    de: 'ps: Falls Sie aus unbekannten Gründen 4 Tage vor Ihrer Abreise keine Tickets haben, kontaktieren Sie uns bitte. 💌',
    nl: 'ps: Als om een onbekende reden u 4 dagen voor vertrek geen tickets hebt ontvangen, neem dan contact met ons op. 💌',
  },
  noDiscountCard: {
    id: `${confirmationScope}.noDiscountCard`,
    defaultMessage: 'Aucune carte de réduction',
    en: 'No discount card',
    it: 'Nessuna carta sconto',
    es: 'Sin tarjeta de descuento',
    de: 'Keine Rabattkarte',
    nl: 'Geen kortingskaart',
  },
  totalFor: {
    id: `${confirmationScope}.totalFor`,
    defaultMessage: 'Total pour',
    en: 'Total for',
    it: 'Totale per',
    es: 'Total para',
    de: 'Gesamt für',
    nl: 'Totaal voor',
  },
  reservedOn: {
    id: `${confirmationScope}.reservedOn`,
    defaultMessage: 'Billet réservé le :',
    en: 'Ticket reserved on:',
    it: 'Biglietto prenotato il:',
    es: 'Boleto reservado el:',
    de: 'Ticket reserviert am:',
    nl: 'Ticket gereserveerd op:',
  },
  adminEmailConfirmation: {
    id: `${confirmationScope}.adminEmailConfirmation`,
    defaultMessage: 'Vos billets ont également été envoyés à l’adresse :',
    en: 'Your tickets have also been sent to the address:',
    it: "I tuoi biglietti sono stati inviati all'indirizzo:",
    es: 'Tus boletos también han sido enviados a la dirección:',
    de: 'Ihre Tickets wurden auch an die Adresse gesendet:',
    nl: 'Uw tickets zijn ook naar het adres verzonden:',
  },
  edit: {
    id: `${confirmationScope}.edit`,
    defaultMessage: 'Modifier',
    en: 'Edit',
    it: 'Modifica',
    es: 'Modificar',
    de: 'Bearbeiten',
    nl: 'Bewerken',
  },
  yourReservationNumber: {
    id: `${confirmationScope}.yourReservationNumber`,
    defaultMessage: 'Votre numéro de réservation est :',
    en: 'Your reservation number is:',
    it: 'Il tuo numero di prenotazione è:',
    es: 'Su número de reserva es:',
    de: 'Ihre Buchungsnummer lautet:',
    nl: 'Uw reserveringsnummer is:',
  },
  copy: {
    id: `${confirmationScope}.copy`,
    defaultMessage: 'Copier',
    en: 'Copy',
    it: 'Copia',
    es: 'Copiar',
    de: 'Kopieren',
    nl: 'Kopiëren',
  },
  downloadAllTickets: {
    id: `${confirmationScope}.downloadAllTickets`,
    defaultMessage: 'Télécharger tous les billets',
    en: 'Download All Tickets',
    it: 'Scarica tutti i biglietti',
    es: 'Descargar todos los boletos',
    de: 'Alle Tickets herunterladen',
    nl: 'Download alle tickets',
  },
  cancelledTrip: {
    id: `${confirmationScope}.cancelledTrip`,
    defaultMessage: 'Trajet annulé',
    en: 'Cancelled Trip',
    it: 'Viaggio annullato',
    es: 'Viaje cancelado',
    de: 'Fahrt abgesagt',
    nl: 'Geannuleerde reis',
  },
  cancelTicket: {
    id: `${confirmationScope}.cancelTicket`,
    defaultMessage: 'Annuler le billet',
    en: 'Cancel Ticket',
    it: 'Annulla il biglietto',
    es: 'Cancelar el billete',
    de: 'Ticket stornieren',
    nl: 'Annuleer het ticket',
  },
  generateCard: {
    id: `${confirmationScope}.generateCard`,
    defaultMessage: 'Générer une carte bancaire',
    en: 'Generate a credit card',
    it: 'Genera una carta di credito',
    es: 'Generar una tarjeta de crédito',
    de: 'Kreditkarte generieren',
    nl: 'Genereer een creditcard',
  },
  anErrorOccurredInYourOrder: {
    id: `${confirmationScope}.anErrorOccurredInYourOrder`,
    defaultMessage: 'Une erreur s’est glissée dans votre voyage.',
    en: 'An error has occurred in your journey.',
    it: 'Si è verificato un errore nel tuo viaggio.',
    es: 'Se ha producido un error en tu viaje.',
    de: 'Ein Fehler ist aufgetreten in Ihrer Reise.',
    nl: 'Er is een fout opgetreden in uw reis.',
  },
  haveAGoodTrip: {
    id: `${confirmationScope}.haveAGoodTrip`,
    defaultMessage: 'Bon voyage !',
    en: 'Have a great trip!',
    it: 'Buon viaggio!',
    es: '¡Buen viaje!',
    de: 'Gute Reise!',
    nl: 'Goede reis!',
  },
  haveAGoodTripTo: {
    id: `${confirmationScope}.haveAGoodTripTo`,
    defaultMessage: 'Bon voyage vers',
    en: 'Have a great trip to',
    it: 'Buon viaggio a',
    es: 'Buen viaje a',
    de: 'Gute Reise nach',
    nl: 'Goede reis naar',
  },
  notLeaving: {
    id: `${confirmationScope}.notLeaving`,
    defaultMessage: 'Ah non, vous vous ne partez pas.',
    en: 'Oh no, you are not traveling.',
    it: 'Oh no, tu non stai viaggiando.',
    es: '¡Ah no, tú no estás viajando!',
    de: 'Oh nein, du reist nicht.',
    nl: 'Oh nee, jij reist niet.',
  },

  getCancellationConditionsModalErrorMessage: {
    id: `${confirmationScope}.getCancellationConditionsModalErrorMessage`,
    defaultMessage:
      "Une erreur est survenue lors de la récupération des conditions d'annulation, veuillez réessayer !",
    en: 'An error occurred while retrieving cancellation conditions, please try again!',
    it: 'Si è verificato un errore durante il recupero delle condizioni di cancellazione, si prega di riprovare!',
    es: 'Se ha producido un error al recuperar las condiciones de cancelación, ¡por favor, inténtelo nuevamente!',
    de: 'Ein Fehler ist aufgetreten beim Abrufen der Stornobedingungen, bitte versuchen Sie es erneut!',
    nl: 'Er is een fout opgetreden bij het ophalen van de annuleringsvoorwaarden, probeer het opnieuw!',
  },
  getCancellationConditionsModalLoadingMessage: {
    id: `${confirmationScope}.getCancellationConditionsModalLoadingMessage`,
    defaultMessage: "Récupération des conditions d'annulation...",
    en: 'Retrieving cancellation conditions...',
    it: 'Recupero delle condizioni di cancellazione...',
    es: 'Recuperación de las condiciones de cancelación...',
    de: 'Abrufen der Stornobedingungen...',
    nl: 'Ophalen van annuleringsvoorwaarden...',
  },
  closeErrorModal: {
    id: `${confirmationScope}.closeErrorModal`,
    defaultMessage: 'Fermer',
    en: 'Close',
    it: 'Chiudi',
    es: 'Cerrar',
    de: 'Schließen',
    nl: 'Sluiten',
  },
  cancelTicketErrorMessage: {
    id: `${confirmationScope}.cancelTicketErrorMessage`,
    defaultMessage:
      "Une erreur est survenue lors de l'annulation du trajet, veuillez réessayer !",
    en: 'An error occurred while canceling the trip, please try again!',
    it: "Si è verificato un errore durante l'annullamento del viaggio, riprova!",
    es: 'Se ha producido un error al cancelar el viaje, ¡por favor, inténtalo de nuevo!',
    de: 'Beim Stornieren der Reise ist ein Fehler aufgetreten, bitte versuche es erneut!',
    nl: 'Er is een fout opgetreden bij het annuleren van de reis, probeer het opnieuw!',
  },
  cancelTicketLoadingMessage: {
    id: `${confirmationScope}.cancelTicketLoadingMessage`,
    defaultMessage: "Réservation en cours d'annulation",
    en: 'Canceling Reservation',
    it: 'Annullamento della prenotazione in corso',
    es: 'Cancelando Reserva',
    de: 'Reservierung wird storniert',
    nl: 'Bezig met annuleren van reservering',
  },
  cancelTicketErrorPrimaryButton: {
    id: `${confirmationScope}.cancelTicketErrorPrimaryButton`,
    defaultMessage: 'Réessayer',
    en: 'Retry',
    it: 'Riprova',
    es: 'Reintentar',
    de: 'Erneut versuchen',
    nl: 'Opnieuw proberen',
  },
  cancelTicketConfirmationMessage: {
    id: `${confirmationScope}.cancelTicketConfirmationMessage`,
    defaultMessage:
      'Ne fermez pas cette fenêtre tant que les modifications ne sont pas enregistrées.',
    en: 'Do not close this window until the changes are saved.',
    it: 'Non chiudere questa finestra fino a quando le modifiche non sono state salvate.',
    es: 'No cierre esta ventana hasta que se guarden los cambios.',
    de: 'Schließen Sie dieses Fenster nicht, bis die Änderungen gespeichert sind.',
    nl: 'Sluit dit venster niet totdat de wijzigingen zijn opgeslagen.',
  },
  cancelTicketConfirmationTitle: {
    id: `${confirmationScope}.cancelTicketConfirmationTitle`,
    defaultMessage: 'Bravo ! Le trajet a bien été annulé',
    en: 'Congratulations! The trip has been successfully canceled.',
    it: 'Bravo! Il viaggio è stato annullato con successo.',
    es: '¡Bravo! El viaje ha sido cancelado correctamente.',
    de: 'Herzlichen Glückwunsch! Die Fahrt wurde erfolgreich storniert.',
    nl: 'Gefeliciteerd! De reis is succesvol geannuleerd.',
  },
  cancelThisTrip: {
    id: `${confirmationScope}.cancelThisTrip`,
    defaultMessage: 'Annuler ce trajet',
    en: 'Cancel this trip',
    it: 'Annulla questo viaggio',
    es: 'Cancelar este viaje',
    de: 'Diese Fahrt stornieren',
    nl: 'Deze reis annuleren',
  },
  cancelThisTripTitle: {
    id: `${confirmationScope}.cancelThisTripTitle`,
    defaultMessage: 'Annulation du trajet',
    en: 'Cancel Trip',
    it: 'Annullamento del viaggio',
    es: 'Cancelar viaje',
    de: 'Fahrt stornieren',
    nl: 'Reis annuleren',
  },
  updateMailConfirmationMessage: {
    id: `${confirmationScope}.updateMailConfirmationMessage`,
    defaultMessage:
      'Les informations relatives au voyage seront envoyées à la nouvelle adresse.',
    en: 'Travel information will be sent to the new address.',
    it: 'Le informazioni di viaggio saranno inviate alla nuova indirizzo.',
    es: 'La información de viaje se enviará a la nueva dirección.',
    de: 'Reiseinformationen werden an die neue Adresse gesendet.',
    nl: 'Reisinformatie wordt naar het nieuwe adres gestuurd.',
  },
  updateMailConfirmationTitle: {
    id: `${confirmationScope}.updateMailConfirmationTitle`,
    defaultMessage: 'Bravo ! L’email a bien été modifié',
    en: 'Congratulations! The email has been successfully modified',
    it: "Bravo! L'email è stato modificato con successo",
    es: '¡Bravo! El correo electrónico ha sido modificado correctamente',
    de: 'Bravo! Die E-Mail wurde erfolgreich geändert',
    nl: 'Gefeliciteerd! De e-mail is succesvol aangepast',
  },
  updateMailModalButton: {
    id: `${confirmationScope}.updateMailModalButton`,
    defaultMessage: 'Valider',
    en: 'Validate',
    it: 'Conferma',
    es: 'Validar',
    de: 'Bestätigen',
    nl: 'Valideren',
  },
  updateMailModalTitle: {
    id: `${confirmationScope}.updateMailModalTitle`,
    defaultMessage: 'Modifier l’adresse email',
    en: 'Modify Email Address',
    it: 'Modifica Indirizzo Email',
    es: 'Modificar dirección de correo electrónico',
    de: 'E-Mail-Adresse ändern',
    nl: 'E-mailadres wijzigen',
  },
  emailAddress: {
    id: `${confirmationScope}.emailAddress`,
    defaultMessage: 'Adresse email',
    en: 'Email address',
    it: 'Indirizzo email',
    es: 'Dirección de correo electrónico',
    de: 'E-Mail-Adresse',
    nl: 'E-mailadres',
  },
  existingAddress: {
    id: `${confirmationScope}.existingAddress`,
    defaultMessage: 'Adresse existante',
    en: 'Existing Address',
    it: 'Indirizzo esistente',
    es: 'Dirección existente',
    de: 'Vorhandene Adresse',
    nl: 'Bestaand adres',
  },
  newAddress: {
    id: `${confirmationScope}.newAddress`,
    defaultMessage: 'Nouvelle adresse',
    en: 'New Address',
    it: 'Nuovo Indirizzo',
    es: 'Nueva Dirección',
    de: 'Neue Adresse',
    nl: 'Nieuw Adres',
  },
  send: {
    id: `${confirmationScope}.send`,
    defaultMessage: 'Envoyer',
    en: 'Send',
    it: 'Invia',
    es: 'Enviar',
    de: 'Senden',
    nl: 'Verzenden',
  },
  passengers: {
    id: `${confirmationScope}.passengers`,
    defaultMessage: 'Les passagers',
    en: 'Passengers',
    it: 'Passeggeri',
    es: 'Pasajeros',
    de: 'Passagiere',
    nl: 'Passagiers',
  },
  scanQRCode: {
    id: `${confirmationScope}.scanQRCode`,
    defaultMessage:
      'Scannez le code QR pour ouvrir votre billet dans l’application Tictactrip !',
    en: 'Scan the QR code to open your ticket in the Tictactrip app!',
    it: "Scansiona il codice QR per aprire il tuo biglietto nell'app Tictactrip!",
    es: 'Escanee el código QR para abrir su boleto en la aplicación de Tictactrip.',
    de: 'Scannen Sie den QR-Code, um Ihr Ticket in der Tictactrip-App zu öffnen!',
    nl: 'Scan de QR-code om uw ticket te openen in de Tictactrip-app!',
  },
};

export default defineMessages(confirmationMessages);
